var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.handleAddressSubmit(reset))}}},[(_vm.isAddNewAddressFormVisible)?_c('div',{staticClass:"form"},[_c('ValidationProvider',{attrs:{"name":"sdi","rules":"required|min:2","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('name'),expression:"'name'"}],staticClass:"form__element",attrs:{"value":_vm.billingDetails.name,"label":_vm.$t('Company Region (or Name and Surname)'),"name":"name","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeBillingDetails('name', $event)}}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"partita","rules":("required|" + (/[a-z]/i.test(_vm.billingDetails.partita) ? 'codice_fiscale' : 'digits:11')),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('partita'),expression:"'partita'"}],staticClass:"form__element",attrs:{"value":_vm.billingDetails.partita,"label":_vm.$t('VAT number'),"name":"partita","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeBillingDetails('partita', $event)}}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"sdi","vid":"sdi","rules":"alpha_num|length:7","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('sdi'),expression:"'sdi'"}],staticClass:"form__element",attrs:{"value":_vm.billingDetails.sdi,"label":_vm.$t('SDI'),"name":"sdi","required":false,"valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeBillingDetails('sdi', $event)}}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"pec","vid":"sec","rules":"email","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('pec'),expression:"'pec'"}],staticClass:"form__element",attrs:{"value":_vm.billingDetails.pec,"label":_vm.$t('PEC'),"name":"pec","type":"email","required":false,"valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeBillingDetails('pec', $event)}}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"street","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('streetName'),expression:"'streetName'"}],staticClass:"form__element form__element--half",attrs:{"value":_vm.billingDetails.street,"label":_vm.$t('Street Name'),"name":"streetName","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeBillingDetails('street', $event)}}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"apartment","rules":"required|min:1","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('apartment'),expression:"'apartment'"}],staticClass:"form__element form__element--half form__element--half-even",attrs:{"value":_vm.billingDetails.apartment,"label":_vm.$t('House/Apartment number'),"name":"apartment","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeBillingDetails('apartment', $event)}}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"city","rules":"required|min:2","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('city'),expression:"'city'"}],staticClass:"form__element form__element--half",attrs:{"value":_vm.billingDetails.city,"label":_vm.$t('City'),"name":"city","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeBillingDetails('city', $event)}}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"state","rules":!_vm.regionInformation ? null : 'required|min:2',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.billingDetails.country_code || _vm.regionInformation.length === 0)?_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('state'),expression:"'state'"}],staticClass:"form__element form__element--half form__element--half-even",attrs:{"label":_vm.$t('Province'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":!_vm.billingDetails.country_code,"name":"state"},on:{"input":function($event){return _vm.changeBillingDetails('region', $event)}},model:{value:(_vm.billingDetails.region),callback:function ($$v) {_vm.$set(_vm.billingDetails, "region", $$v)},expression:"billingDetails.region"}}):_c('SfSelect',{directives:[{name:"e2e",rawName:"v-e2e",value:('state'),expression:"'state'"}],staticClass:"form__element form__element--half form__element--half-even form__select sf-select--underlined",attrs:{"label":_vm.$t('Province'),"name":"state","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeBillingDetails('region', $event)}},model:{value:(_vm.billingDetails.region),callback:function ($$v) {_vm.$set(_vm.billingDetails, "region", $$v)},expression:"billingDetails.region"}},[_c('SfSelectOption',{attrs:{"value":''}}),_vm._v(" "),_vm._l((_vm.regionInformation),function(regionOption){return _c('SfSelectOption',{key:regionOption.id,attrs:{"value":regionOption.abbreviation}},[_vm._v("\n            "+_vm._s(regionOption.label)+"\n          ")])})],2)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"country_code","rules":"required|min:2","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfSelect',{directives:[{name:"e2e",rawName:"v-e2e",value:('country'),expression:"'country'"}],staticClass:"form__element form__element--half form__select sf-select--underlined",attrs:{"value":_vm.billingDetails.country_code,"label":_vm.$t('Country'),"name":"country","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":_vm.changeCountry}},[_c('SfSelectOption',{attrs:{"value":''}}),_vm._v(" "),_vm._l((_vm.countriesList),function(countryOption){return _c('SfSelectOption',{key:countryOption.id,attrs:{"value":countryOption.abbreviation}},[_vm._v("\n            "+_vm._s(countryOption.label)+"\n          ")])})],2)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"postcode","rules":"required|digits:5","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('zipcode'),expression:"'zipcode'"}],staticClass:"form__element form__element--half form__element--half-even",attrs:{"value":_vm.billingDetails.postcode,"label":_vm.$t('Zip-code'),"name":"zipCode","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeBillingDetails('postcode', $event)}}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"telephone","rules":"required|min:8|numeric","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('phone'),expression:"'phone'"}],staticClass:"form__element form__element--half",attrs:{"value":_vm.billingDetails.telephone,"label":_vm.$t('Phone number'),"name":"phone","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeBillingDetails('telephone', $event)}}})]}}],null,true)})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex gap-2 justify-between mt-4"},[_c('SfButton',{staticClass:"border-2 border-body-300 rounded-full text-system-400 bg-white normal-case py-3 px-5",attrs:{"type":"button"},on:{"click":function($event){_vm.$router.push(("" + (_vm.localePath('/checkout/shipping'))))}}},[_vm._v("\n        "+_vm._s(_vm.$t('Go back'))+"\n      ")]),_vm._v(" "),_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('continue-to-payment'),expression:"'continue-to-payment'"}],staticClass:"primary border-2 border-system-300 rounded-full bg-system-300 text-system-100 normal-case py-3 px-5",attrs:{"type":"submit","disabled":!!(!_vm.canMoveForward || invalid)}},[_vm._v("\n        "+_vm._s(_vm.$t('Continue'))+"\n      ")])],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }