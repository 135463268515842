//
//
//
//
//

import Vue from "vue";
import Invoice from '~/modules/checkout/components/Invoices/Invoice.vue'

Vue.component("Invoice", Invoice);

export default {
  name: "Invoices",
  props: {
    openTab: {
      type: Number,
      default: 1,
    },
    tabMaxContentHeight: {
      type: String,
      default: "",
    },
    tabShowText: {
      type: String,
      default: "show",
    },
    tabHideText: {
      type: String,
      default: "hide",
    },
  },
  data() {
    return {
      initialTabActivated: false,
    };
  },
  watch: {
    openTab(newValue, oldValue) {
      if (newValue === oldValue) return;
      this.toggle(this.$children[newValue - 1]._uid);
    },
  },
  mounted() {
    this.$on("toggle", this.toggle);
    if (this.openTab) this.openChild();
  },
  methods: {
    toggle(id) {
      this.$children.forEach((child) => {
        child.isActive = child._uid === id;
      });
      const activeTab =
        this.$children.findIndex((child) => child.isActive === true) + 1;
      this.$emit("click:tab", activeTab);
    },
    openChild() {
      if (this.openTab < this.$children.length + 1) {
        this.$children[this.openTab - 1].isActive = true;
        this.initialTabActivated = true;
      }
    },
  },
  provide: function () {
    const tabConfig = {};
    Object.defineProperty(tabConfig, "tabMaxContentHeight", {
      get: () => this.tabMaxContentHeight,
    });
    Object.defineProperty(tabConfig, "tabShowText", {
      get: () => this.tabShowText,
    });
    Object.defineProperty(tabConfig, "tabHideText", {
      get: () => this.tabHideText,
    });
    return {
      tabConfig,
    };
  },
};
