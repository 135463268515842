//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfInput } from '@storefront-ui/vue';
import { ValidationProvider, extend } from 'vee-validate';

export default {
  name: "CodeFiscale",
  components: {
    SfInput,
    ValidationProvider
  },
  data() {
    return {
      fiscalCode: '',
    };
  },
}
